import React, { Fragment } from 'react';

import { Flex } from 'rebass';
import styled from 'styled-components';

import Header from '../components/header';
import Links from '../components/links';

const Container = styled.main`
	a,
	h2 {
		font-family: system-ui, sans-serif;
	}
	a {
		text-decoration: underline;
		font-weight: bold;
		color: #000;
	}
`;

const IndexPage = () => (
	<Fragment>
		<Container>
			<Flex flexWrap="wrap">
				<Header />
				<Links />
			</Flex>
		</Container>
	</Fragment>
);

export default IndexPage;
