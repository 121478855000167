import React, { Fragment } from 'react'

import { Heading, Box } from 'rebass'

const Header = () => (
  <Fragment>
    <Box
      p={4}
      pb={4}
      fontSize={7}
      width={1}
    >
      <Heading>Brandon Moore</Heading>
    </Box>
  </Fragment>
)

export default Header