import React, { Fragment, useState } from 'react';

import { Flex, Box, Link, Text } from 'rebass';

const Links = () => {
	const [ textVisible, displayText ] = useState(false);

	return (
		<Fragment>
			<Box p={4} pt={1} alignSelf="flex-end" width={[ 1 ]}>
				<Link href="#" onClick={() => displayText(!textVisible)}>
					Mail
				</Link>
			</Box>
			<Box p={4} pt={1} alignSelf="flex-end" width={[ 1 ]}>
				<Link href="https://www.linkedin.com/in/brandonkm">Work</Link>
			</Box>
			{textVisible && (
				<Flex>
					<Box width={1} p={4} pt={1}>
						<Text>Send messages to: hi@brandonkm.net</Text>
					</Box>
				</Flex>
			)}
		</Fragment>
	);
};

export default Links;
